import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.handleClick)
    this.element.shadowRoot?.addEventListener('click', this.handleClick)
  }

  disconnect() {
    this.element.removeEventListener('click', this.handleClick)
    this.element.shadowRoot?.removeEventListener('click', this.handleClick)
  }

  handleClick = e => {
    const path = e.composedPath ? e.composedPath() : e.path
    const target = path.find(element => element.dataset?.timestamp)
    if (target) {
      e.preventDefault()
      const values = target.dataset.timestamp.split(':').map(Number)
      const withHours = values.length === 3
      const hoursInSeconds = (withHours ? values[0] : 0) * 60 * 60
      const minutesInSeconds = (withHours ? values[1] : values[0]) * 60
      const seconds = withHours ? values[2] : values[1]
      const payload = hoursInSeconds + minutesInSeconds + seconds
      document.querySelector('#program-controller').scrollIntoView()
      this.seekVideo(payload)
    }
  }

  seekVideo(payload) {
    const player = document.querySelector('[data-controller*=program-video]')
    player?.command('seek', { time: payload })
  }

  get videoController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('#program-controller'),
      'program',
    )
  }
}
